import React from 'react';
import SearchBar from '../component/search/searchBar';
// import SearchRecent from '../component/search/searchRecent';
import BottomBar from '../component/global/bottomBar';
const SearchPage = () => {
    return (
        <>
        <SearchBar></SearchBar>
        <BottomBar></BottomBar>
        </>
    );
};

export default SearchPage;
